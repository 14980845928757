import React, { Fragment } from 'react';
import { Link } from 'gatsby';

import Accordion from '@components/_ui/Accordion/Accordion';
import AccordionItem from '@components/_ui/Accordion/AccordionItem';
import Downloads from './_ui/Downloads';

// import FaqData from '@data/faq.json';

const Faq = () => {
    const data = [
        {
            title: 'What&rsquo;s the difference between a pergola, an arbour and a gazebo?',
            question: <Fragment>What&rsquo;s the difference between a pergola, an arbour and a gazebo?</Fragment>,
            answer:
    <Fragment>
        <p>Here at Perfect Pergolas we often receive calls from potential customers confused about the difference between these garden structures. There is an incredible amount of information out there in 'internet land' however - most of it is just as bemusing and in some cases simply plain wrong! There are differences between a pergola, arbour and a gazebo and knowing them will help you choose your garden structure with confidence.</p>
        <p>So, here goes with our very own definitive list:</p>
        <ol>
            <li>
                <strong>Gazebo:</strong><br />
                <p>A gazebo is a stand-alone structure, usually with sides and a roof, frequently situated to maximise available views, of the garden itself or the landscape beyond. They are often hexagonal or octagonal in design. Some have lattice-work sides and built-in seating, giving them a sturdy, enclosed feel. They are structures in their own right and are rarely vehicles for plants.</p>
            </li>
            <li>
                <strong>Arbour:</strong><br />
                <p>An arbour is a short &lsquo;walk-through&rsquo; structure, usually made of wood, that connects one part of the garden with another (say, the vegetable garden to a formal lawn area). These are often used for climbers, particularly roses.</p>
                <p>There are also smaller, stand-alone arbours with latticed sides, backs and built in seating, for secluded comtemplation of the garden. These arbours with seats are sometimes referred to as Bowers. They can carry climbing plants for additional sensory and romantic appeal.</p>
            </li>
            <li>
                <strong>Pergola:</strong><br />
                <span>There are two types of pergola:</span><br /><br />
                <span>a) &lsquo;Lean-to&rsquo;</span><br />
                <span>b) &lsquo;Stand-alone&rsquo;</span><br /><br />
                <p>The traditional &lsquo;lean-to&rsquo; pergola is frequently made of wood and built against the house to provide a transitory area from the inside to the outside. During the Renaissance period in particular it gave ladies the opportunity to take a walk or sit outside without damaging their complexions.</p>
                <p>The &lsquo;stand-alone&rsquo; pergola, again usually made of wood, features uprights but no solid sides or roof. These can be positioned attractively in the garden and allow some degree of protection from the harsh rays of the sun, particuarly if planted up with shading climbers.</p>
                <p>Both types of pergola are, in effect, frames fro climbing plants and vines that surround the occupants with foliage, flowers and aromas and offer a measure of protection from the weather and some privacy in urban areas.</p>
            </li>
        </ol>
    </Fragment>
        },
        {
            title: 'Why buy a pergola?',
            question: <Fragment>Why buy a pergola?</Fragment>,
            answer:
    <Fragment>
        <p>Look at some of the benefits:</p>
        <ol>
            <li>Extra outside space that looks great and offers protection</li>
            <li>Cost effective</li>
            <li>No planning permission (local conditions may apply)</li>
            <li>Complements your home</li>
            <li>A ‘greener’ outdoor lifestyle</li>
            <li>Takes advantage of our better summers</li>
            <li>Adds value to your house</li>
            <li>Extra privacy when entertaining outdoors</li>
            <li>Only timber from sustainable sources is used</li>
        </ol>
        <p>Plus they all come with a ten year guarantee and delivered free of charge to your doorstep!</p>
    </Fragment>
        },
        {
            title: 'I need a pergola or arbour made specifically for my garden, can you help?',
            question: <Fragment>I need a pergola or arbour made specifically for my garden, can you help?</Fragment>,
            answer:
    <Fragment>
        <p>Yes of course - we'd be delighted!</p>
        <p>Try our bespoke pergola design service, the <Link to="/planner/" title="Visit the Pergola Planner">Pergola Planner</Link>.</p>
    </Fragment>
        },
        {
            title: 'Where can I put a pergola?',
            question: <Fragment>Where can I put a pergola?</Fragment>,
            answer:
    <Fragment>
        <p>There are 2 different sorts of pergolas:</p>
        <p>1) Traditional &lsquo;Lean-to&rsquo; Mediterranean pergolas</p>
        <p>2) &lsquo;Stand-alone&rsquo; garden or patio pergolas</p>
        <p>The &lsquo;Lean-to&rsquo; pergolas are designed to be attached to your house or perhaps a wall or garage in your garden. The 'Stand-alone' pergolas can be positioned away from buildings - anywhere in the garden that is big and flat enough in effect!</p>
        <p>There are a few planning requirements to adhere to and we have produced the following list to help you decide if you need to contact your local planning department - please follow the link below:</p>
        <p><a href="www.perfectpergolas.co.uk/pergola-planning/planning" title="Planning your pergola">www.perfectpergolas.co.uk/pergola-planning/planning</a></p>
        <p>Because the Outdoor Garden Design Act 2008 is interpreted in different ways by the different local planning departments we always recommend that it worth a call to your local council to inform them of what you are planning to do. However, as a timber frame is not considered a permanent building or structure it will be fine as long as you do not infringe any of the guidelines above.</p>
    </Fragment>
        },
        {
            title: 'Where do you deliver to and are there any hidden costs?',
            question: <Fragment>Where do you deliver to and are there any hidden costs?</Fragment>,
            answer:
    <Fragment>
        <p>We deliver nationally and there are no hidden costs as we offer a free service. We use a specialist carrier that works for our Saw Mill and are very experienced in the transport of timber. They will be given your telephone or mobile number so that they can contact you for any last minute directions or to keep you informed as to progress in case there are any traffic jams along the route. (Charges may apply if you live in the Highlands, Islands or Northern Ireland – please contact us for details)</p>
    </Fragment>
        },
        {
            title: 'How is the pergola delivered to me?',
            question: <Fragment>How is the pergola delivered to me?</Fragment>,
            answer:
    <Fragment>
        <p>The pergola is delivered to you by our specialist transport team. When your pergola has been machined and is about to enter the 24hr pressurised treatment tank, we will contact you to arrange a convenient date and date when you, a member of the family or friend, can be at your home to sign for the delivery. Our dedicated team will unload the pergola components and leave it in a place where you would like it put until you are ready to assemble it.</p>
    </Fragment>
        },
        {
            title: 'Do you have any online guides for assembly?',
            question: <Fragment>Do you have any online guides for assembly?</Fragment>,
            answer:
    <Fragment>
        <p>Yes - there are assembly guides for all our standard pergola modules and these can be found <Link to="/shop" title="Browse modular pergolas">here</Link></p>
        <div className="container">
            <Downloads
                files={[
                    {
                        filename: 'Pergola assembly guide',
                        path: '/downloads/ASSEMBLY.pdf',
                    },
                    {
                        filename: 'Module 1 - assembly guide',
                        path: '/downloads/PP1-assembly.pdf',
                    },
                    {
                        filename: 'Module 2 - assembly guide',
                        path: '/downloads/PP2-assembly.pdf',
                    },
                    {
                        filename: 'Module 3 - assembly guide',
                        path: '/downloads/PP3-assembly.pdf',
                    },
                    {
                        filename: 'Module 4 - assembly guide',
                        path: '/downloads/PP4-assembly.pdf',
                    },
                    {
                        filename: 'Module 5 - assembly guide',
                        path: '/downloads/PP5-assembly.pdf',
                    },
                    {
                        filename: 'Module 6 - assembly guide',
                        path: '/downloads/PP6-assembly.pdf',
                    },
                    {
                        filename: 'Module 7 - assembly guide',
                        path: '/downloads/PP7-assembly.pdf',
                    },
                    {
                        filename: 'Module 8 - assembly guide',
                        path: '/downloads/PP8-assembly.pdf',
                    },
                ]}
            />
        </div>
    </Fragment>
        },
        {
            title: 'Do you provide an assembly service?',
            question: <Fragment>Do you provide an assembly service?</Fragment>,
            answer:
    <Fragment>
        <p>No we do not offer an assembly service - most notably because we have designed these pergolas to be as simple as possible to assemble. We do recommend that if you want to find someone to assemble the project for you that you use Google to find a couple of local garden landscapers (perferably BALI approved) who could give you a quote for the job.</p>
        <p>To give you an idea our smallest MOD 1 pergola should take no more than 2 - 3 hours to complete and our largest MOD 6 pergola would be between 5 - 6 to assemble.</p>
    </Fragment>
        },
        {
            title: 'Is it easy to assemble?',
            question: <Fragment>Is it easy to assemble?</Fragment>,
            answer:
    <Fragment>
        <p>Our pergolas are already machined, cut and pre-drilled at the Saw Mill and come with all the necessary fixings including brackets, coach bolt sets, screws, washers and a choice of post supports. All you have to do is follow the assembly guide and your pergola will be up in no time. We have put togther a short list of the site preparation that should be carried out first:</p>
        <p><a href="www.perfectpergolas.co.uk/pergola-planning/preparation" title="Preparing your space for a pergola">www.perfectpergolas.co.uk/pergola-planning/preparation</a> </p>
        <p>Plus a guide to the recommended tools required:</p>
        <p><a href="www.perfectpergolas.co.uk/pergola-planning/tools-and-materials-required" title="See which tools and materials are required.">www.perfectpergolas.co.uk/pergola-planning/tools-and-materials-required</a></p>
        <p>and finally a few hints to make the job easier!</p>
        <p><a href="www.perfectpergolas.co.uk/pergola-planning/hints-to-make-pergola-assembly-easier" title="See hints about making the pergola assembly easier">www.perfectpergolas.co.uk/pergola-planning/hints-to-make-pergola-assembly-easier</a></p>
        <p>You can also just pick up the phone and chat to us and we will give whatever advice we can.</p>
    </Fragment>
        },
        {
            title: 'How do I assemble a lean-to pergola?',
            question: <Fragment>How do I assemble a lean-to pergola?</Fragment>,
            answer:
    <Fragment>
        <p>Our lean-to pergolas can be assembled in 10 easy steps, wath the video below to find out more or view our online guide here: <a href="www.perfectpergolas.co.uk/pergola-assembly/how-it-works" title="How the assembly works">www.perfectpergolas.co.uk/pergola-assembly/how-it-works</a></p>
        <div className="responsive-embed">
            <iframe title="pergola-assembly-youtube" width="1206" height="678" src="https://www.youtube.com/embed/tu0ITRmUCEQ" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
        </div>
    </Fragment>
        }
    ];
    return (
        <div className="flow--medium">
            <h2 className="text-subtitle text-align--center">Frequently asked questions</h2>
            <Accordion>
                {data.map((item) => (
                    <AccordionItem key={item.title} title={item.title} heading={item.question} content={item.answer} />
                ))}
            </Accordion>
        </div>
    );
};

Faq.defaultProps = {

};

export default Faq;
