import React from 'react';
import { Link } from 'gatsby';

import Icon from '@components/_ui/Icon';

const ModularCallToAction = () => (
    <div className="cta" style={{ backgroundImage: 'url(/img/buy-cta-bg.jpg)' }}>
        <div>
            <h2 className="text-subtitle">Buy modular pergolas</h2>
            <p className="text-paragraph">Choose from one of our 8 pre-designed modules for your perfect pergola.</p>
        </div>
        <div>
            <Link to="/shop/" className="button button--white cta__button" title="Browse modular pergolas">
                <span>Browse modular pergolas</span>
                <Icon id="arrow-right" className="button__icon" />
            </Link>
        </div>
    </div>
);

export default ModularCallToAction;
