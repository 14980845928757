import React from 'react';

import Layout from '@layouts/Layout';

import Icon from '@components/_ui/Icon';
import Logo from '@components/_ui/Logo';
import ModuleSelector from '@components/modules/ModuleSelector';
import BespokeCallToAction from '@components/call-to-actions/BespokeCallToAction';
import ModularCallToAction from '@components/call-to-actions/ModularCallToAction';
import CreateCallout from '@components/callouts/CreateCallout';
import ContactCallout from '@components/callouts/ContactCallout';
import Faq from '@components/Faq';
import Testimonials from '@components/Testimonials';

const Homepage = () => (
    <Layout
        invertNav
        mainClassName="flow--xlarge"
        metaData={{ title: 'Home page' }}
    >
        <header className="hero hero--splash" style={{ backgroundImage: 'url(/img/home-splash.jpg)' }}>
            {/* <iframe className="hero__video" src="https://player.vimeo.com/video/286025268?background=1" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe> */}
            <div className="hero__container container">
                <Logo className="icon--white" />
            </div>
        </header>
        <section className="pull--top--large flow--skip">
            <div className="container flow--large">
                <div>
                    <div className="grid-x grid-margin-x grid-margin-y">
                        <div className="cell medium-6">
                            <ModularCallToAction />
                        </div>
                        <div className="cell medium-6">
                            <BespokeCallToAction />
                        </div>
                    </div>
                </div>
                <h3 className="combo-heading combo-heading--centered">
                    <span className="text-subtitle">Find your perfect pergola or arbour</span>
                    <span className="text-paragraph text-color--steelbook">Customised to fit your garden</span>
                </h3>
                <div>
                    <div className="grid-x grid-margin-x grid-margin-y">
                        <div className="cell medium-6 large-4">
                            <div className="bracket feature-block">
                                <Icon id="wood" className="bracket__fixed feature-block__icon" />
                                <div className="bracket__flex">
                                    <h4 className="text-label">Choose from a range of timber options</h4>
                                    <span className="text-metadata">Different choices of woods & finishes designed to make the most of your garden</span>
                                </div>
                            </div>
                        </div>
                        <div className="cell medium-6 large-4">
                            <div className="bracket feature-block">
                                <Icon id="truck" className="bracket__fixed feature-block__icon" />
                                <div className="bracket__flex">
                                    <h4 className="text-label">We deliver straight to your door - for free!</h4>
                                    <span className="text-metadata">Free UK delivery on all pergolas and arbours - direct to your door</span>
                                </div>
                            </div>
                        </div>
                        <div className="cell medium-6 large-4">
                            <div className="bracket feature-block">
                                <Icon id="screw" className="bracket__fixed feature-block__icon" />
                                <div className="bracket__flex">
                                    <h4 className="text-label">Quick and easy to assemble</h4>
                                    <span className="text-metadata">Our comprehensive assembly guides will help you get your perfect pergola up in no time</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
        <section className="wrapper-top--default wrapper-bottom--small background-color--chalk">
            <div className="container">
                <h2 className="combo-heading">
                    <span className="text-subtitle">Choose from our pre-defined modules</span>
                    <span className="text-paragraph text-color--steelbook">Ideal for most homes and gardens</span>
                </h2>
            </div>
            <ModuleSelector />
        </section>

        <section className="container">
            <CreateCallout />
        </section>

        <Testimonials />

        <section className="container container--small">
            <Faq />
        </section>

        <section className="container">
            <ContactCallout />
        </section>
    </Layout>
);

export default Homepage;
