import React from 'react';
import { Link } from 'gatsby';

import Icon from '@components/_ui/Icon';

const BespokeCallToAction = () => (
    <div className="cta" style={{ backgroundImage: 'url(/img/bespoke-cta-bg.jpg)' }}>
        <div>
            <h2 className="text-subtitle">Bespoke designs</h2>
            <p className="text-paragraph">Use our bespoke pergola planner to turn your sketches and ideas into reality.</p>
        </div>

        <div>
            <Link to="/planner/" className="button button--white cta__button" title="Design your perfect pergola">
                <span>Design your bespoke pergola</span>
                <Icon id="arrow-right" className="button__icon" />
            </Link>
        </div>
    </div>
);

export default BespokeCallToAction;
