import React, { useState, useEffect } from 'react';

const Accordion = ({
    children, defaultItemIndexOpen, className
}) => {
    const [itemOpen, setItemOpen] = useState(defaultItemIndexOpen); // This stores the index of which item is open
    const [enabled, setEnabled] = useState(false);

    // PE THING: Used to flag that JS is working and we can
    // layer stuff onto the accordion
    useEffect(() => {
        setEnabled(true);
    }, []);

    return (
        <ul className={`accordion${enabled ? ' _is-active' : ''} ${className}`}>
            {React.Children.map(children, (child, i) => React.cloneElement(child, {
                index: i,
                handleOpen: setItemOpen,
                open: itemOpen === i,
                enabled
            }))}
        </ul>
    );
};

Accordion.defaultProps = {
    mobileOnly: false,
    className: '',
    defaultItemIndexOpen: undefined
};

export default Accordion;
