import React, { useState, useEffect } from 'react';

import Icon from '@components/_ui/Icon';

const AccordionItem = ({
    index, open, enabled, handleOpen, title, heading, content, headingClassName
}) => {
    const itemRef = React.createRef();

    const [maxHeight, setMaxHeight] = useState('0px');

    useEffect(() => {
        if (itemRef && itemRef.current) {
            setMaxHeight(open ? itemRef.current.scrollHeight + 32 : '0px');
        }
    }, [itemRef, open]);

    return (
        <li className={`accordion__item ${!open ? '_is-collapsed' : ''}`}>
            <button
                title={title}
                className="accordion__trigger"
                type="button"
                onClick={() => {
                    handleOpen(open ? null : index);
                }}
            >
                <span className={`accordion__heading ${headingClassName}`}>{heading}</span>
                {enabled && <Icon id="chevron-down" className="accordion__icon" />}
            </button>
            <div
                ref={itemRef}
                className="accordion__content wysiwyg"
                style={enabled ? { maxHeight } : {}}
            >
                {content}
            </div>
        </li>
    );
};

AccordionItem.defaultProps = {
    headingClassName: 'text-subheading',
    title: '',
    index: undefined,
    open: undefined,
    enabled: undefined,
    handleOpen: undefined
};

export default AccordionItem;
