import React from 'react';

import Icon from '@components/_ui/Icon';

const TestimonialCard = ({ data, className }) => {
    if (!data) {
        return false;
    }

    return (
        <div className={`testimonial ${className}`}>
            <div className="testimonial__image">
                <img src={data.imageUrl} alt={data.imageAlt} />
            </div>
            <div className="testimonial__content">
                <div className="flow--small">
                    <Icon id="quote" className="icon--medium icon--cloudy-sky" />
                    <p className="text-paragraph">{data.quote}</p>
                </div>

                <span className="text-subheading">{data.author}</span>
            </div>
        </div>
    );
};

TestimonialCard.defaultProps = {
    data: null,
    className: '',
};

export default TestimonialCard;
