import React from 'react';
import { Link } from 'gatsby';

import Carousel from '@components/_ui/Carousel';
import TestimonialCard from '@components/_ui/TestimonialCard';

const Testimonials = () => {
    const testimonials = [
        {
            quote: "We have a pergola supplied by Perfect Pergola's in our garden - it is looking great with the roses climbing up it.",
            author: 'Caroline Tatham – Principle, Cotswold Gardening School',
            imageUrl: '/img/showcase/landscape-one.jpg',
            imageAlt: 'A medium-sized lean-to pergola over some decking, strung with decorative lights and surrounded by assorted potted flowers.'
        },
        {
            quote: 'Just thought that you might like to see how the project turned out. The vine will fill out next year but we are already delighted with the results. Many thanks for the excellent piece of kit.',
            author: 'Noel Berry, Suffolk',
            imageUrl: '/img/showcase/portrait-one.jpg',
            imageAlt: 'A customer example of module 6, built on a raised tiled dias'
        },
        {
            quote: 'May I say what a pleasure it’s been doing business with Perfect Pergolas!',
            author: 'Chris Roberts, Sussex',
            imageUrl: '/img/showcase/mod5-oak.jpg',
            imageAlt: 'A customer example of module 5, stained with a dark wood stain, and built on a rough stone patio'
        },
        {
            quote: 'Great pergola – fills up the blank wall space and makes the most of the outdoor space. Very pleased!',
            author: 'Mr G Knapp, Sutton Coalfield',
            imageUrl: '/img/showcase/mod2-pergola-against-house.jpg',
            imageAlt: 'An example of module 2, built against a brick house, and surrounded by a barbeque, wicker furniture, and potted flowers'
        },
        {
            quote: 'Fantastic pergola! Just what we were looking for to grow our vines up. Great service!',
            author: ' Mr D Shaw, Alton',
            imageUrl: '/img/showcase/20mtr-pergola-3.jpg',
            imageAlt: 'An example of 20 meter pergola, and surrounded by a view in the country side.'
        },
    ];

    const carouselOpts = {
        pageDots: true,
        prevNextButtons: true,
    };

    return (
        <section className="wrapper background-color--chalk flow--medium">
            <Carousel options={carouselOpts} itemClassName="reel__item--testimonial">
                {testimonials.map((t) => <TestimonialCard data={t} key={t.author} />)}
            </Carousel>
            <div className="text-align--center">
                <Link to="/customer-showcase/" className="button" title="View customer pergolas and testimonials">
                    <span>View customer showcase</span>
                </Link>
            </div>
        </section>
    );
};

Testimonials.defaultProps = {

};

export default Testimonials;
